<template>
  <section
    id="budgetSearch"
    class="budget-search-prefilter"
    :style="backgroundStyles"
  >
    <div class="budget-search-prefilter__content">
      <h2>Set a budget</h2>

      <div class="budget-search-prefilter__panel">
        <FilterBudgetSlider
          v-model:price="price"
          v-model:monthly-price="monthlyPrice"
          :vehicle-condition="filters.condition"
          :tracking-options="{ pageCategory }"
          @select-tab="updateBudgetTypeSelected"
        />
        <client-only>
          <div class="text-sm text-gray-500">
            <p class="mt-4">
              Monthly Budget: The monthly budget search is based on a
              Representative example calculation until you personalise your
              finance quote.
            </p>
            <p v-if="!isFinancePersonalised" class="mt-4">
              Finance based on
              <strong>Agility (Personal Contract Plan)</strong>, with a deposit
              of <strong>17.5%</strong> of the price, a duration of
              <strong>48 months</strong>, and <strong>10,000 miles</strong> per
              year.
            </p>
          </div>
        </client-only>
      </div>
      <OsButtonTray>
        <client-only>
          <FinanceEditButton
            v-if="isFinancePersonalised"
            :is-disabled="isLoading"
            aria-label="Edit finance"
            @click="goToResults"
          />
        </client-only>
        <FinanceAcceptButton
          is-primary
          :is-disabled="isLoading"
          @click="applyFilter"
        />
      </OsButtonTray>
    </div>
  </section>
</template>

<script lang="ts">
import { ERoutes, ERouteConditions } from '~/types/routes'
import { ESortIds } from '~/types/filters'
import { EBudgetTab } from '~/types/components/budget'
import { useFiltersStore } from '~/stores/filtersStore'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useFinanceCriteriaStore } from '~/stores/finance/criteriaStore'

export default {
  setup() {
    const { $dataLayer } = useNuxtApp()
    const route = useRoute()
    const router = useRouter()
    const filtersStore = useFiltersStore()
    const customerTypeStore = useCustomerTypeStore()
    const financeCriteriaStore = useFinanceCriteriaStore()
    const budgetTypeSelected = ref(EBudgetTab.MonthlyPrice)
    const isLoading = ref<boolean>(false)
    const { filters, selectedBudget, updateBudget, saveFilters } = useFilters(
      filtersStore.active
    )

    const price = computed<number[]>({
      get() {
        return [selectedBudget.value.price.Min, selectedBudget.value.price.Max]
      },
      set(value) {
        updateBudget({
          price: { Min: value[0], Max: value[1] }
        })
      }
    })

    const monthlyPrice = computed<number[]>({
      get() {
        return [
          selectedBudget.value.monthlyPrice.Min,
          selectedBudget.value.monthlyPrice.Max
        ]
      },
      set(value) {
        updateBudget({
          monthlyPrice: { Min: value[0], Max: value[1] }
        })
      }
    })

    const { isViewportDesktop } = useUi()
    const img = useImage()
    const backgroundStyles = computed(() => {
      const size = isViewportDesktop ? 'lg' : 'sm'
      const imgUrl = img(`/images/BudgetSearchPrefilter/${size}.jpg`, {
        width: isViewportDesktop.value ? 1280 : 640,
        format: 'webp,jpg'
      })

      return { backgroundImage: `url('${imgUrl}')` }
    })

    const isFinancePersonalised = computed<boolean>(() => {
      return financeCriteriaStore.isPersonalised
    })

    const pageCategory = computed<string>(() => {
      return `budget: ${
        budgetTypeSelected.value === EBudgetTab.MonthlyPrice
          ? 'monthly'
          : 'price'
      }`
    })

    const pageCondition = computed<ERouteConditions>(() => {
      return (
        (route.params.condition as ERouteConditions) || ERouteConditions.New
      )
    })

    const goToResults = () => {
      router
        .push({
          name: ERoutes.Results,
          params: {
            condition: pageCondition.value,
            type: customerTypeStore.getCustomerTypeRouteParam
          },
          query: filtersStore.getActiveQueryParams
        } as any)
        .catch(() => {})
    }

    const applyFilter = async (track = true) => {
      if (isLoading.value) return

      await nextTick()

      isLoading.value = true
      filtersStore.updateSorting(ESortIds.MonthlyPriceAscending)
      await saveFilters(false)

      if (track) {
        $dataLayer.linkClick({
          pageCategory: pageCategory.value,
          category: 'set a budget',
          action: 'search',
          forms: [
            {
              name: 'set a budget',
              field: 'min',
              event: 'submit',
              value: filters.value.budget[budgetTypeSelected.value].Min
            },
            {
              name: 'set a budget',
              field: 'max',
              event: 'submit',
              value: filters.value.budget[budgetTypeSelected.value].Max
            }
          ]
        })
      }

      goToResults()
    }

    const updateBudgetTypeSelected = (type: EBudgetTab) => {
      budgetTypeSelected.value = type
    }

    return {
      filters,
      price,
      monthlyPrice,
      budgetTypeSelected,
      isLoading,
      isFinancePersonalised,
      pageCategory,
      pageCondition,
      goToResults,
      applyFilter,
      updateBudgetTypeSelected,
      backgroundStyles
    }
  }
}
</script>

<style lang="scss" scoped>
.budget-search-prefilter {
  height: rem(600);
  width: 100%;
  padding: 0;
  border-radius: 6px;
  background-size: cover;
  background-color: $black--darkest;

  h2 {
    margin-bottom: rem(24);
    color: $white;
  }

  &:deep(.os-slider__marks__values) {
    font-size: rem(14);
  }
  &:deep(.budget-search__tabs) {
    border: 0;
  }
  &:deep(.budget-search__tooltip svg path) {
    fill: $white;
  }
  &:deep(.textInput) {
    .body--kiosk & {
      pointer-events: none;
      user-select: none;
    }
  }
  &:deep(.button-tray) {
    position: relative;
    margin-top: rem(24);
    max-width: unset;
    width: 100%;
    left: 0;
    right: unset;
    @include viewport(md) {
      max-width: unset;
      width: 100%;
    }

    .edit-finance__button {
      width: auto;
    }
  }
}

.budget-search-prefilter__panel {
  padding: rem(32);
  border-radius: 6px;
  background-color: $grey--lightest;
}
.budget-search-prefilter__content {
  padding: rem(32);
  max-width: rem(536);
  padding: rem(16);
  display: flex;
  flex-direction: column;
  height: inherit;
  position: relative;

  @include viewport(md) {
    padding: rem(40);
  }
}
</style>
